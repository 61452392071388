.WalkText {
    display: block;

    &.expandable {
        .ReadMoreButton {
            padding: 0;
            border: 0;
        }
    }
}
