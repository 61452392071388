@import "bootstrap/dist/css/bootstrap";

$background-color: #E7ECEF;

body {
    background-color: $background-color;
}

.mernik {
    position: fixed;
    top: calc(50vh);
    left: calc(50vw);
    z-index: 9999;
}

@font-face {
    font-family: 'Mont Heavy';
    src: url('./style/Mont-HeavyDEMO.otf') format('opentype');
}
