
$navbar-color: #274C77;

.Layout {

  .Header {
    .navbar-brand {
       font-weight: bold;
       font-family: 'Mont Heavy', serif;
     }
  }

  .LayoutContent {
    margin-top: 5em;
  }

  .Footer {
    .FooterText {
      font-size: 0.9em;
      color: grey;
    }
  }
}